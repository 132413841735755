import { useAuth } from "../../hooks/auth";

import logoImg from "../../assets/logo01-color.png";

function Header() {
  const { user, signOut } = useAuth();

  return (
    <nav className="navbar bg-success mb-5 text-white shadow">
      <div className="container-fluid">
        <div className="d-flex">
          <img
            src={logoImg}
            alt=""
            style={{
              height: "40px",
              marginRight: "8px",
            }}
          />
          <h1 className="navbar-brand">Central Calcário</h1>
        </div>
        <div className="d-flex">
          <p className="me-2 mb-auto mt-auto">
            Boas vindas, {user.name?.split(" ")[0]}
          </p>
          <button className="btn btn-outline-light" onClick={signOut}>
            Sair
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Header;
