import { useCallback, useRef } from "react";
import { toast } from "react-toastify";

import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";

import logoImg from "../../assets/logo-background.png";

import Input from "../../components/Form/Input";
import { useAuth } from "../../hooks/auth";
import getValidationErrors from "../../utils/getValidationErrors";
import InputMask from "../../components/Form/InputMask";

function SignIn() {
  const formRef = useRef<FormHandles>(null);

  formRef.current?.setFieldValue("cpf", "03666354173");
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data) => {
      const schema = Yup.object().shape({
        cpf: Yup.string().required("Campo CPF é obrigatório"),
        password: Yup.string().required("Campo senha é obrigatório"),
      });

      formRef.current?.setErrors({});

      try {
        await schema.validate(data, { abortEarly: false });

        await signIn(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          formRef.current?.setErrors(errors);
          return;
        }
        toast.error("Falha ao fazer login. Favor, tente novamente");
      }
    },
    [signIn]
  );

  return (
    <div className="container">
      <main className="form-signin w-75 mx-auto mt-5">
        <div style={{ width: "100%", textAlign: "center" }}>
          <img
            src={logoImg}
            alt="Logo Central Calcário"
            className=" mb-3  img-fluid"
            style={{ maxWidth: "50%" }}
          />
        </div>

        {/*
        // @ts-expect-error tipagem do unform desatualizada */}
        <Form onSubmit={handleSubmit} ref={formRef}>
          <InputMask
            label="CPF"
            name="cpf"
            placeholder="CPF"
            autoComplete="off"
            maskType="cpf"
            maskPlaceholder={null}
          />
          <Input
            label="Senha"
            name="password"
            type="password"
            containerClass={"mt-1"}
            placeholder="Senha"
          />

          <button className="w-100 btn btn-lg btn-success mt-2" type="submit">
            Acessar conta
          </button>
        </Form>
      </main>
    </div>
  );
}

export default SignIn;
