import React, { InputHTMLAttributes, useEffect, useRef } from "react";

import { useField } from "@unform/core";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  containerClass?: string;
}
const Input: React.FC<IInputProps> = ({
  name,
  label,
  containerClass,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div className={`form-floating ${containerClass || ""}`}>
      <input
        id={`${fieldName}`}
        ref={inputRef}
        defaultValue={defaultValue}
        className={`form-control ${error ? "is-invalid" : ""}`}
        {...rest}
      />
      <label htmlFor={`${fieldName}`}>{label}</label>
      {error && <span className="invalid-feedback">{error}</span>}
    </div>
  );
};

export default Input;
