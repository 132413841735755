import { Redirect, Switch } from "react-router-dom";

import SignIn from "../pages/Auth/SignIn";
import Home from "../pages/Home";
import OrderShipments from "../pages/OrderShipments";
import Route from "./Route";

export default function Routes() {
  return (
    <Switch>
      <Route path="/sign-in" component={SignIn} />

      <Route isPrivate exact path="/" component={Home} />
      <Route
        isPrivate
        path="/order-shipments/:pedido_id"
        component={OrderShipments}
      />

      <Redirect to="/" />
    </Switch>
  );
}
