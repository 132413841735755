import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Lottie from "react-lottie";

import Header from "../../components/Header";
import api from "../../service/api";
import formatDate from "../../utils/formatDate";

import loadingData from "../../assets/loading-files.json";
import notFoundImage from "../../assets/not-found.svg";

interface PedidoData {
  pedido_id: string;
  type_product: string;
  quantity: number;
  amount: number;
  quantity_delivered: number;
  pedido_created_at: string;
}

export default function Home() {
  const [pedidos, setPedidos] = useState<PedidoData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await api.get("/demands");

        setPedidos(data);
      } catch (error) {
        toast.error("Erro ao carregar dados");
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  if (loading) {
    return (
      <>
        <Header />
        <div className="container">
          <main>
            <h2 className="text-center text-muted">Carregando pedidos...</h2>

            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loadingData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              isClickToPauseDisabled={true}
              style={{
                cursor: "auto",
              }}
            />
          </main>
        </div>
      </>
    );
  }

  if (pedidos.length < 1) {
    return (
      <>
        <Header />
        <div className="container">
          <main>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <img
                src={notFoundImage}
                alt="not found"
                width={120}
                height={120}
              />
              <h2 className="text-muted mt-2">Nenhum pedido encontrado...</h2>
            </div>
          </main>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="container">
        <header className="mb-4">
          <h1>Visão geral dos pedidos</h1>
        </header>

        <div className="overflow-auto">
          <table className="table table-bordered border-primary table-striped table-responsive text-center">
            <thead>
              <tr>
                <th>Número do pedido</th>
                <th>Quantidade total pedido (Ton)</th>
                <th>Quantidade total entregue (Ton)</th>
                <th>Saldo a retirar (Ton)</th>
                <th>Data do pedido</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {pedidos.map((pedido) => (
                <tr key={pedido.pedido_id}>
                  <td>{pedido.pedido_id}</td>
                  <td>{Intl.NumberFormat("pt-br").format(pedido.quantity)}</td>
                  <td>
                    {Intl.NumberFormat("pt-br").format(
                      pedido.quantity_delivered
                    )}
                  </td>
                  <td>
                    {Intl.NumberFormat("pt-br").format(
                      pedido.quantity - pedido.quantity_delivered
                    )}
                  </td>
                  <td>{formatDate(pedido.pedido_created_at)}</td>
                  <td>
                    <Link to={`order-shipments/${pedido.pedido_id}`}>
                      Visualizar carregamentos
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
