import "react-toastify/dist/ReactToastify.min.css";
import "./styles.css";

import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import AppProvider from "./hooks";
import Routes from "./routes";

export default function App() {
  return (
    <>
      <ToastContainer />
      <AppProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AppProvider>
    </>
  );
}
