import {
  useEffect,
  useRef,
  CSSProperties,
  useMemo,
  InputHTMLAttributes,
} from "react";
import ReactInputMask, { Props as InputProps } from "react-input-mask";

import { useField } from "@unform/core";

type IInputMaskProps = InputHTMLAttributes<HTMLInputElement> &
  Omit<InputProps, "mask">;

interface IInputProps extends IInputMaskProps {
  name: string;
  label: string;
  containerClass?: CSSProperties;
  maskType: "cep" | "cpf" | "cnpj" | "phone";
}

export default function InputMask({
  label,
  name,
  maskType,
  className,
  containerClass = {},
  ...rest
}: IInputProps) {
  const inputRef = useRef(null);

  const { defaultValue, error, fieldName, registerField } = useField(name);

  const maskInput = useMemo(() => {
    let mask = "";

    switch (maskType) {
      case "cep":
        mask = "99999-999";
        break;
      case "cnpj":
        mask = "99.999.999/9999-99";

        break;
      case "cpf":
        mask = "999.999.999-99";

        break;
      case "phone":
        mask = "+55 (99) 9 9999 9999";

        break;
    }

    return mask;
  }, [maskType]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue(ref: any) {
        let inputValue = ref.value as string;

        switch (maskType) {
          case "cep":
            inputValue = inputValue.replace("-", "");

            break;
          case "cnpj":
            inputValue = inputValue.replaceAll(".", "");
            inputValue = inputValue.replaceAll("-", "");
            inputValue = inputValue.replaceAll("/", "");

            break;
          case "cpf":
            inputValue = inputValue.replaceAll(".", "");
            inputValue = inputValue.replace("-", "");

            break;
          case "phone":
            inputValue = inputValue.replaceAll(" ", "");
            inputValue = inputValue.replace("(", "");
            inputValue = inputValue.replace(")", "");

            break;
        }

        return inputValue;
      },
      setValue(ref, value) {
        ref.value = value;
      },
    });
  }, [registerField, fieldName, maskType]);

  return (
    <div className={`form-floating ${containerClass || ""}`}>
      <ReactInputMask
        id={fieldName}
        name={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        mask={maskInput}
        className={`form-control ${error ? "is-invalid" : ""}`}
        {...rest}
      />

      <label htmlFor={`${fieldName}`}>{label}</label>
      {error && <span className="invalid-feedback">{error}</span>}
    </div>
  );
}
