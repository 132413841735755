import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "../../components/Header";
import api from "../../service/api";
import formatDate from "../../utils/formatDate";
import formatNumber from "../../utils/formatNumber";
import handleMessageError from "../../utils/handleMessageError";

import loadingData from "../../assets/loading-files.json";
import notFoundImage from "../../assets/not-found.svg";
import { format, parseISO } from "date-fns";

interface PedidoData {
  id: number;
  pedido_id: string;

  product: {
    quantity: number;
    unitary_value: number;
  };

  shipping: {
    loaded_at: string;
    vehicle: {
      placa: string;
      uf: string;
    };
  };
}

interface IRouteParams {
  pedido_id: string;
}

const OrderShipments: React.FC = () => {
  const [orders, setOrders] = useState<PedidoData[]>([]);
  const [loading, setLoading] = useState(true);

  const { pedido_id } = useParams() as IRouteParams;

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await api.get(`/demands/${pedido_id}/orders`);

        setOrders(data);
      } catch (error) {
        toast.warn(handleMessageError(error));
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [pedido_id]);

  if (loading) {
    return (
      <>
        <Header />
        <div className="container">
          <main>
            <h2 className="text-center text-muted">
              Buscando carregamentos...
            </h2>

            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: loadingData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              isClickToPauseDisabled={true}
              style={{
                cursor: "auto",
              }}
            />
          </main>
        </div>
      </>
    );
  }

  if (orders.length < 1) {
    return (
      <>
        <Header />
        <div className="container">
          <header className="mb-4">
            <Link to="/">Voltar</Link>
            <h1>Carregamentos do pedido {pedido_id}</h1>
          </header>

          <div className="text-center text-info">
            <img src={notFoundImage} alt="not found" width={180} height={180} />

            <h2>Não foi encontrado nenhum carregamento em nosso sistema.</h2>
            <h2>
              Aguarde para mais detalhes ou entre em contato com o vendedor.
            </h2>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="container">
        <header className="mb-4">
          <Link to="/">Voltar</Link>
          <h1>Carregamentos do pedido {pedido_id}</h1>
        </header>

        <div className="overflow-auto">{TabelaPedidos(groupByDay(orders))}</div>
      </div>
    </>
  );
};

// Função para agrupar carregamentos por dia
const groupByDay = (orders: PedidoData[]) => {
  const groupedOrders: { [key: string]: PedidoData[] } = {};

  orders.forEach((order) => {
    const date = format(parseISO(order.shipping.loaded_at), "yyyy-MM-dd");

    if (!groupedOrders[date]) {
      groupedOrders[date] = [];
    }
    groupedOrders[date].push(order);
  });

  return groupedOrders;
};

const TabelaPedidos = (groupedOrders: any) => (
  <table className="table table-bordered border-primary table-striped table-responsive text-center">
    <thead>
      <tr>
        <th>Data do carregamento</th>
        <th>Placa do veículo</th>
        <th>Quantidade (TN) carregado</th>
        <th>Valor unitário</th>
        <th>Valor total</th>
      </tr>
    </thead>
    <tbody>
      {Object.entries(groupedOrders).map((params: any) => {
        const [date, orders] = params as [date: string, orders: PedidoData[]];

        const totalValue = orders.reduce(
          (total, order) =>
            total + order.product.unitary_value * order.product.quantity,
          0
        );

        const totalLoaded = orders.reduce(
          (total, order) => total + order.product.quantity,
          0
        );

        return (
          <React.Fragment key={date}>
            {orders.map((order) => (
              <tr key={order.id}>
                <td>{formatDate(order.shipping.loaded_at)}</td>
                <td>
                  {order.shipping.vehicle.uf} - {order.shipping.vehicle.placa}
                </td>
                <td>
                  {Intl.NumberFormat("pt-br").format(order.product.quantity)}
                </td>

                <td>
                  {formatNumber({
                    data: order.product.unitary_value,
                    type: "currency",
                  })}
                </td>
                <td>
                  {formatNumber({
                    data: order.product.unitary_value * order.product.quantity,
                    type: "currency",
                  })}
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <strong>
                  Total do dia {parseISO(date).toLocaleDateString()}
                </strong>
              </td>

              <td></td>

              <td>
                <strong>
                  {Intl.NumberFormat("pt-br").format(totalLoaded)}
                </strong>
              </td>

              <td></td>

              <td>
                <strong>
                  {formatNumber({ data: totalValue, type: "currency" })}
                </strong>
              </td>
            </tr>
          </React.Fragment>
        );
      })}
    </tbody>
  </table>
);

export default OrderShipments;
