import axios from "axios";

const prefix = "customers/";
const api_url = process.env.REACT_APP_API_URL || "http://localhost:3333";

const api = axios.create({
  baseURL: `${api_url}/${prefix}`,
});

export default api;
