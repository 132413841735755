import React, { createContext, useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";

import api from "../service/api";

interface IUser {
  id: string;
  name?: string;
}

interface ISignCredentials {
  cpf: string;
  password: string;
}

interface IAuthState {
  user: IUser;
  token: string;
}

interface IAuthContextData {
  user: IUser;
  signIn(credentials: ISignCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

const AuthProvider: React.FC<any> = ({ children }) => {
  const [authData, setAuthData] = useState<IAuthState>(() => {
    const token = localStorage.getItem("@central-calcario-client:token");
    const user = localStorage.getItem("@central-calcario-client:user");

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      api.get("/me").catch(() => {
        localStorage.removeItem("@central-calcario-client:token");
        localStorage.removeItem("@central-calcario-client:user");

        toast.info("Sua sessão expirou. Faça login novamente.", {
          toastId: "Sua sessão expirou. Faça login novamente.",
        });

        return setAuthData({} as IAuthState);
      });

      return { token, user: JSON.parse(user) };
    }

    return {} as IAuthState;
  });

  const signIn = useCallback(async ({ cpf, password }: ISignCredentials) => {
    try {
      const response = await api.post("/sessions", { cpf, password });

      const { user, token } = response.data;

      localStorage.setItem(
        "@central-calcario-client:user",
        JSON.stringify(user)
      );
      localStorage.setItem("@central-calcario-client:token", token);

      api.defaults.headers.authorization = `Bearer ${token}`;

      setAuthData({ token, user });
    } catch (error: any) {
      throw new Error(error?.response?.data?.message);
    }
  }, []);

  const signOut = useCallback((): void => {
    localStorage.removeItem("@central-calcario-client:user");
    localStorage.removeItem("@central-calcario-client:token");

    setAuthData({} as IAuthState);
  }, []);

  return (
    <AuthContext.Provider value={{ user: authData.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
